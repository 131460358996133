import { LatLng } from '@/models';
import { tryFetchToJson } from '@/utils/http-request';

import { LocationPhases, SimpleLocationDto } from './dto';

export const getNearestLocations = async (props: {
  locale: string;
  slugs?: string[];
  phases?: LocationPhases[];
  limit: number;
  latLng?: LatLng;
}): Promise<SimpleLocationDto[] | undefined> => {
  const { locale, ...filters } = props;

  return tryFetchToJson<SimpleLocationDto[]>({
    fetchParams: {
      input: '/api/nearest-locations',
      init: {
        headers: {
          'Accept-Language': locale,
        },
        body: JSON.stringify(filters),
        method: 'POST',
      },
    },
    skipFetchError: true,
  });
};
