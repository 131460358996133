import { NextApiRequest } from 'next';

import { captureException, captureMessage } from './sentry';

export enum HttpMethods {
  Get = 'GET',
  Post = 'POST',
  Put = 'PUT',
  Patch = 'PATCH',
  Options = 'OPTIONS',
  Delete = 'DELETE',
}

export enum HttpErrorNames {
  MethodNotAllowed = 'MethodNotAllowed',
}

export type OnErrorResParams = {
  status: number;
  body: any;
};

export async function tryFetchToJson<T>(params: {
  fetchParams: { input: RequestInfo | URL; init?: RequestInit | undefined };
  onSuccess?: (response: T) => any;
  onFunctionalError?: (res?: OnErrorResParams) => any;
  onTechnicalError?: (err: any) => any;
  withoutResponse?: boolean;
  skipFetchError?: boolean;
  withAuthToken?: boolean;
}): Promise<T> {
  const {
    fetchParams,
    onSuccess,
    onFunctionalError,
    onTechnicalError,
    withoutResponse,
    skipFetchError,
  } = params;

  const fetchParamsInit = {
    ...fetchParams.init,
    headers: {
      ...fetchParams.init?.headers,
      ...(params.withAuthToken && {
        Authorization: `Bearer ${await getUserToken()}`,
      }),
    },
  };

  try {
    const res = await fetch(fetchParams.input, fetchParamsInit);

    if (res.status >= 400) {
      captureMessage(
        `Failed to fetch ${fetchParams.input} with status code ${res.status}`,
        res.status < 500 ? 'warning' : 'error'
      );

      try {
        const body = await res.json();
        return onFunctionalError?.({ status: res.status, body });
      } catch {
        return onFunctionalError?.({ status: res.status, body: undefined });
      }
    }

    const response =
      res.status === 204 || withoutResponse ? null : ((await res.json()) as T);

    if (onSuccess == null) {
      return response as T;
    }

    return onSuccess(response as T);
  } catch (err: any) {
    if (!skipFetchError) {
      captureException(
        `Failed to fetch ${fetchParams.input}: ${err?.toString()}`,
        err
      );
    }

    return onTechnicalError?.(err);
  }
}

export function createHttpError(
  message: string,
  response?: OnErrorResParams
): Error {
  if (response == null) {
    return Error(message);
  }

  return Error(message, {
    cause: {
      status: response.status,
      body: response.body,
    },
  });
}

export function isHttpMethodAllowed(params: {
  req: NextApiRequest;
  allowedMethods: HttpMethods[];
}) {
  const { req, allowedMethods } = params;

  const method = req.method;

  return allowedMethods.includes(method as HttpMethods);
}

export function getClientIp(req: NextApiRequest): string {
  let ip = req.headers['x-real-ip'] as string;

  const forwardedFor = req.headers['x-forwarded-for'] as string;
  if (!ip && forwardedFor) {
    ip = forwardedFor?.split(',').at(0) ?? 'Unknown';
  }

  return ip;
}

export const getUserToken = async () => {
  const AuthHelpers = await import('@/lib/authentication/helpers');
  const { isValid } = await AuthHelpers.checkUserToken();
  const { token } = await AuthHelpers.getUserToken();

  let userToken = token;
  if (!isValid) {
    try {
      const { message } = await AuthHelpers.refreshUserToken();
      userToken = message.refreshToken.token;
    } catch (error) {
      AuthHelpers.logout(); // making sure we remove auth data from local storage
    }
  }

  return userToken;
};
