import { getServerConfig } from '@/utils/config';
import { tryFetchToJson } from '@/utils/http-request';

import {
  MicroLocationDto,
  SimpleIndustriousLocationSearchDto,
  SimpleLocationDto,
  SimpleLocationSearchDto,
} from './dto';
import { LocationAndNearLocationsDto } from './dto/location-and-near-locations.dto';
import { LocationFilterDto } from './dto/location-filter.dto';
import { LocationHierarchies } from './dto/location-hierarchies.dto';

export enum GetAllLocationsResponseTypes {
  Full = 'full',
  Micro = 'micro',
}

export async function fetchLocationBySlug(
  slug: string,
  locale: string
): Promise<LocationAndNearLocationsDto | undefined> {
  const url = `${
    getServerConfig().NEXT_PUBLIC_INVENTORY_API_URL
  }/discovery/locations/${slug}`;

  return tryFetchToJson<LocationAndNearLocationsDto>({
    fetchParams: {
      input: url,
      init: {
        headers: { 'Accept-Language': locale },
      },
    },
  });
}

type FetchAllLocationsProps = {
  locale: string;
  responseType?: GetAllLocationsResponseTypes;
  withSubscriptionProductsOnly?: boolean;
  withCoworkingDayPassOnly?: boolean;
} & LocationFilterDto;

export async function fetchIndustriousMicroLocations(
  locale: string
): Promise<MicroLocationDto[]> {
  return fetchAllIndustriousLocations({
    locale,
    responseType: GetAllLocationsResponseTypes.Micro,
  }) as Promise<MicroLocationDto[]>;
}

export async function fetchAllLocations(
  params: FetchAllLocationsProps
): Promise<SimpleLocationSearchDto[]> {
  const { locale, responseType, ...filters } = params;

  const host = getServerConfig().NEXT_PUBLIC_INVENTORY_API_URL;

  const url = `${host}/discovery/locations/all?responseType=${
    responseType || GetAllLocationsResponseTypes.Full
  }`;

  const locations = await tryFetchToJson<SimpleLocationSearchDto[]>({
    fetchParams: {
      input: url,
      init: {
        headers: {
          'Accept-Language': locale,
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify(filters),
      },
    },
    skipFetchError: true,
  });

  return locations ?? [];
}

export async function fetchAllIndustriousLocations(
  props: FetchAllLocationsProps
): Promise<SimpleIndustriousLocationSearchDto[]> {
  return fetchAllLocations({
    ...props,
    onlyInternal: true,
  }) as Promise<SimpleIndustriousLocationSearchDto[]>;
}

export async function fetchLocationHierarchies(props: {
  locale: string;
}): Promise<LocationHierarchies | undefined> {
  const { locale } = props;

  const url = `${
    getServerConfig().NEXT_PUBLIC_INVENTORY_API_URL
  }/discovery/locations/hierarchies`;

  return tryFetchToJson<LocationHierarchies>({
    fetchParams: {
      input: url,
      init: { headers: { 'Accept-Language': locale } },
    },
  });
}

export async function fetchNearestLocation(
  props: {
    locale: string;
    longitude: number;
    latitude: number;
    withOnlineAccessCheckout?: boolean;
  } & LocationFilterDto
): Promise<SimpleLocationDto | undefined> {
  const {
    locale,
    longitude,
    latitude,
    slugs,
    phases,
    withOnlineAccessCheckout,
  } = props;

  const url = `${
    getServerConfig().NEXT_PUBLIC_INVENTORY_API_URL
  }/discovery/nearest-location`;
  const body = JSON.stringify({
    longitude,
    latitude,
    slugs,
    phases,
    withOnlineAccessCheckout,
  });

  return tryFetchToJson<SimpleLocationDto>({
    fetchParams: {
      input: url,
      init: {
        headers: {
          'Accept-Language': locale,
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body,
      },
    },
    skipFetchError: true,
  });
}

export async function fetchMultipleNearestLocations(
  props: {
    locale: string;
    longitude: number;
    latitude: number;
    limit: number;
  } & LocationFilterDto
): Promise<SimpleLocationDto | undefined> {
  const { locale, longitude, latitude, slugs, phases, limit } = props;

  const url = `${
    getServerConfig().NEXT_PUBLIC_INVENTORY_API_URL
  }/discovery/nearest-locations`;
  const body = JSON.stringify({
    longitude,
    latitude,
    slugs,
    phases,
    limit,
  });

  return tryFetchToJson<SimpleLocationDto>({
    fetchParams: {
      input: url,
      init: {
        headers: {
          'Accept-Language': locale,
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body,
      },
    },
    skipFetchError: true,
  });
}
