import { LatLng } from '@/models';
import { tryFetchToJson } from '@/utils/http-request';

import { LocationPhases, SimpleLocationDto } from './dto';

export const getNearestLocation = async (props: {
  locale: string;
  withOnlineAccessCheckout?: boolean;
  slugs?: string[];
  phases?: LocationPhases[];
  latLng?: LatLng;
}): Promise<SimpleLocationDto | undefined> => {
  const { locale, ...filters } = props;

  return tryFetchToJson<SimpleLocationDto>({
    fetchParams: {
      input: '/api/nearest-location',
      init: {
        headers: {
          'Accept-Language': locale,
        },
        body: JSON.stringify(filters),
        method: 'POST',
      },
    },
    skipFetchError: true,
  });
};
