import { LocationProductName } from '@/lib/locations/dto';

const PRODUCT_PAGE_ACCESS_IMG = '/public-s3/images/solutions-pages/access.webp';
const PRODUCT_PAGE_OFFICE_IMG =
  '/public-s3/images/solutions-pages/dedicatedOffice.webp';
const PRODUCT_PAGE_MEETING_ROOM_IMG =
  '/public-s3/images/solutions-pages/meetingRoom.webp';
const PRODUCT_PAGE_SUITES_IMG = '/public-s3/images/solutions-pages/suites.webp';
const PRODUCT_PAGE_VIRTUAL_IMG =
  '/public-s3/images/solutions-pages/virtual.webp';

export enum SolutionPageSlugs {
  Suites = 'suites',
  Access = 'access',
  Virtual = 'virtual',
  Conference = 'conference-and-meeting-rooms',
  Offices = 'offices',
}

export function getProductNameFromSlug(
  slug: SolutionPageSlugs
): Exclude<LocationProductName, 'coworkingDayPass'> | null {
  if (slug === SolutionPageSlugs.Conference) {
    return 'meetingRoom';
  }
  if (slug === SolutionPageSlugs.Offices) {
    return 'dedicatedOffice';
  }
  if (['suites', 'access', 'virtual'].includes(slug)) {
    return slug as Exclude<LocationProductName, 'coworkingDayPass'>;
  }

  return null;
}

export function getProductPageImage(productName: LocationProductName) {
  switch (productName) {
    case 'access':
      return PRODUCT_PAGE_ACCESS_IMG;
    case 'dedicatedOffice':
      return PRODUCT_PAGE_OFFICE_IMG;
    case 'meetingRoom':
      return PRODUCT_PAGE_MEETING_ROOM_IMG;
    case 'suites':
      return PRODUCT_PAGE_SUITES_IMG;
    default:
      return PRODUCT_PAGE_VIRTUAL_IMG;
  }
}

export function getProductPerksTranslationsKey(
  productName: LocationProductName
) {
  if (productName === 'access') {
    return ['perk-1', 'perk-2', 'perk-3'];
  }
  return ['perk-1', 'perk-2'];
}
