export type ServerConfig = {
  NEXT_PUBLIC_GREETINGS: string;
  MAPBOX_ACCESS_TOKEN: string;
  IP_INFO_TOKEN: string;
  NEXT_PUBLIC_INVENTORY_API_URL: string;
  NEXT_PUBLIC_OPB_API_URL: string;
  NEXT_PUBLIC_AUTHENTICATION_API_URL: string;
  NEXT_PUBLIC_USER_API_URL: string;
  NEXT_PUBLIC_PRICING_API_URL: string;
  GOOGLE_ANALYTICS_TRACKING_ID?: string;
  NEXT_PUBLIC_SENTRY_DSN?: string;
  NEXT_PUBLIC_SENTRY_ENVIRONMENT?: string;
  SENTRY_DSN?: string;
  SENTRY_ENVIRONMENT?: string;
  ORDER_PAYMENT_BILLING_API_URL: string;
  WORKATO_CHECKOUT_API_KEY: string;
  HOST: string;
  LEADS_API_URL: string;
  WORKATO_LEAD_API_KEY: string;
  IS_PROD: boolean;
  COMMUNICATION_API_URL: string;
  COMMUNICATION_INQUIRY_API_KEY: string;
  WEBHOOK_SECRET_CMS: string;
  CMS_URL: string;
  STRIPE_SECRET_KEY: string;
  NEXT_PUBLIC_COGNITO_APP_ID: string;
  NEXT_PUBLIC_COGNITO_REGION: string;
  NEXT_PUBLIC_COGNITO_USER_POOL: string;
  NEXT_PUBLIC_MAPBOX_STATIC_ACCESS_TOKEN: string;
  NEXT_PUBLIC_RESERVATION_API_URL: string;
  NEXT_PUBLIC_STRIPE_KEY: string;
  NEXT_PUBLIC_PRE_SALE_API_URL: string;
  INVENTORY_SYSTEM_API_URL: string;
  SYSTEM_API_KEY: string;
  PRICING_SYSTEM_API_URL: string;
  USER_SYSTEM_API_URL: string;
  RESERVATION_SYSTEM_API_URL: string;
  NEXT_PUBLIC_MEMBER_PORTAL_URL: string;
  DELTA_ISSUER_BASE_URL: string;
  DELTA_CLIENT_ID: string;
  DELTA_EAM_TEST?: string;
  NEXT_PUBLIC_LOYALTY_DELTA_API_URL: string;
  NEXT_PUBLIC_DELTA_DL_LOYALTY_APP_TEST?: string;
};

export function getServerConfig(): ServerConfig {
  return {
    NEXT_PUBLIC_GREETINGS: process.env.NEXT_PUBLIC_GREETINGS!,
    MAPBOX_ACCESS_TOKEN: process.env.MAPBOX_ACCESS_TOKEN!,
    IP_INFO_TOKEN: process.env.IP_INFO_TOKEN!,
    NEXT_PUBLIC_INVENTORY_API_URL: process.env.NEXT_PUBLIC_INVENTORY_API_URL!,
    NEXT_PUBLIC_OPB_API_URL: process.env.NEXT_PUBLIC_OPB_API_URL!,
    NEXT_PUBLIC_AUTHENTICATION_API_URL:
      process.env.NEXT_PUBLIC_AUTHENTICATION_API_URL!,
    NEXT_PUBLIC_USER_API_URL: process.env.NEXT_PUBLIC_USER_API_URL!,
    GOOGLE_ANALYTICS_TRACKING_ID:
      process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_TRACKING_ID,
    NEXT_PUBLIC_SENTRY_DSN: process.env.NEXT_PUBLIC_SENTRY_DSN,
    NEXT_PUBLIC_SENTRY_ENVIRONMENT: process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT,
    SENTRY_DSN: process.env.SENTRY_DSN,
    SENTRY_ENVIRONMENT: process.env.SENTRY_ENVIRONMENT,
    ORDER_PAYMENT_BILLING_API_URL: process.env.ORDER_PAYMENT_BILLING_API_URL!,
    WORKATO_CHECKOUT_API_KEY: process.env.WORKATO_CHECKOUT_API_KEY!,
    HOST: process.env.NEXT_PUBLIC_HOST!,
    LEADS_API_URL: process.env.LEADS_API_URL!,
    WORKATO_LEAD_API_KEY: process.env.WORKATO_LEAD_API_KEY!,
    IS_PROD: process.env.NEXT_PUBLIC_IS_PROD === 'true',
    COMMUNICATION_API_URL: process.env.COMMUNICATION_API_URL!,
    COMMUNICATION_INQUIRY_API_KEY: process.env.COMMUNICATION_INQUIRY_API_KEY!,
    WEBHOOK_SECRET_CMS: process.env.WEBHOOK_SECRET_CMS!,
    CMS_URL: process.env.CMS_URL!,
    STRIPE_SECRET_KEY: process.env.STRIPE_SECRET_KEY!,
    NEXT_PUBLIC_COGNITO_APP_ID: process.env.NEXT_PUBLIC_COGNITO_APP_ID!,
    NEXT_PUBLIC_COGNITO_REGION: process.env.NEXT_PUBLIC_COGNITO_REGION!,
    NEXT_PUBLIC_COGNITO_USER_POOL: process.env.NEXT_PUBLIC_COGNITO_USER_POOL!,
    NEXT_PUBLIC_MAPBOX_STATIC_ACCESS_TOKEN:
      process.env.NEXT_PUBLIC_MAPBOX_STATIC_ACCESS_TOKEN!,
    NEXT_PUBLIC_PRICING_API_URL: process.env.NEXT_PUBLIC_PRICING_API_URL!,
    NEXT_PUBLIC_RESERVATION_API_URL:
      process.env.NEXT_PUBLIC_RESERVATION_API_URL!,
    NEXT_PUBLIC_STRIPE_KEY: process.env.NEXT_PUBLIC_STRIPE_KEY!,
    NEXT_PUBLIC_PRE_SALE_API_URL: process.env.NEXT_PUBLIC_PRE_SALE_API_URL!,
    INVENTORY_SYSTEM_API_URL: process.env.INVENTORY_SYSTEM_API_URL!,
    SYSTEM_API_KEY: process.env.SYSTEM_API_KEY!,
    PRICING_SYSTEM_API_URL: process.env.PRICING_SYSTEM_API_URL!,
    USER_SYSTEM_API_URL: process.env.USER_SYSTEM_API_URL!,
    RESERVATION_SYSTEM_API_URL: process.env.RESERVATION_SYSTEM_API_URL!,
    NEXT_PUBLIC_MEMBER_PORTAL_URL: process.env.NEXT_PUBLIC_MEMBER_PORTAL_URL!,
    DELTA_ISSUER_BASE_URL: process.env.DELTA_ISSUER_BASE_URL!,
    DELTA_CLIENT_ID: process.env.DELTA_CLIENT_ID!,
    NEXT_PUBLIC_LOYALTY_DELTA_API_URL:
      process.env.NEXT_PUBLIC_LOYALTY_DELTA_API_URL!,
    NEXT_PUBLIC_DELTA_DL_LOYALTY_APP_TEST:
      process.env.NEXT_PUBLIC_DELTA_DL_LOYALTY_APP_TEST,
    DELTA_EAM_TEST: process.env.DELTA_EAM_TEST,
  };
}
